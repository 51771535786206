<template>
  <div class="add-new-item-admin">
    <title-bar
      submitBtn
      :submitBtnTitle="$t('article.create')"
      :title="$t('article.new')"
      @submitPressed="submitPressed"
    />
    <page-form :page-obj="article" :action="action" repository="articles" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import PageForm from '@/views/Admin/Pages/components/PageForm.vue';

export default {
  components: {
    TitleBar,
    PageForm
  },
  data() {
    return {
      action: null,
      article: {
        name: '',
        description: '',
        onOff: false,
        url: '',
        keywords: '',
        metaDescription: '',
        excerpt: '',
        parent: {},
        attachment: null,
        fileURL: null,
        tags: [],
        date: new Date()
      }
    }
  },
  methods: {
    submitPressed() {
      this.action = 'add'
    }
  }
};
</script>
