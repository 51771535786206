<template>
  <b-form @submit.prevent="onSubmit">
    <b-container class="full-w">
      <b-row>
        <b-col cols="12" lg="8">
          <b-card>
            <b-form-group
              :label="pageTitle"
              label-for="page-name"
              :class="{ error: v$.page.name.$errors.length }"
            >
              <b-form-input
                v-model="page.name"
                name="page-name"
                @blur="presetSlug"
              />
              <div
                class="input-errors"
                v-for="error of v$.page.name.$errors"
                :key="error.$uid"
              >
                <small class="text-danger">{{ error.$message }}</small>
              </div>
            </b-form-group>
          </b-card>

          <b-card>
            <b-form-group class="mb-0">
              <tinymce
                :content="page.description"
                @editorContentChanged="updateContent"
              />
            </b-form-group>
          </b-card>
        </b-col>

        <b-col cols="12" lg="4">
          <b-card>
            <b-form-group :label="$t('forms.publish')" label-for="published">
              <b-form-checkbox v-model="page.onOff" name="published" switch/>
            </b-form-group>

            <b-form-group
              :label="$t('forms.parentPage')"
              label-for="parentPage"
              v-if="repository == 'pages'"
            >
              <v-select v-model="page.parent" :options="availablePages"/>
            </b-form-group>

            <b-form-group
              :label="$t('forms.date')"
              label-for="date"
              v-if="repository == 'articles'"
            >
              <datepicker v-model="page.date" format="dd. MM. yyyy | HH:mm"
                          previewFormat="dd. MM. yyyy | HH:mm"></datepicker>
            </b-form-group>

            <b-form-group :label="$t('forms.excerpt')" label-for="page-excerpt">
              <b-form-textarea v-model="page.excerpt" name="page-excerpt"/>
            </b-form-group>

            <b-form-group
              label="URL"
              label-for="page-slug"
              :class="{ error: v$.page.url.$errors.length }"
            >
              <b-form-input
                v-model="page.url"
                name="page-slug"
                @blur="slugifyURL"
              >
                <div
                  class="input-errors"
                  v-for="error of v$.page.url.$errors"
                  :key="error.$uid"
                >
                  <small class="text-danger">{{ error.$message }}</small>
                </div>
              </b-form-input>
            </b-form-group>

            <b-form-group
              :label="$t('forms.keywords')"
              label-for="page-keywords"
            >
              <b-form-input v-model="page.keywords" name="page-keywords"/>
            </b-form-group>

            <b-form-group
              :label="$t('forms.metaDescription')"
              label-for="page-slug"
            >
              <b-form-textarea
                v-model="page.metaDescription"
                name="page-desc"
              />
            </b-form-group>

            <b-form-group v-if="repository === 'articles'" :label="$t('forms.tags')">
              <v-select taggable multiple push-tags v-model="page.tags" :options="page.tags"/>
            </b-form-group>

            <image-upload
              v-if="page['@id']"
              :resource="page['@id']"
              file-attribute="FILE"
              :preview-image="page.fileUrl"
              :label="$t('forms.featuredImage')"
              @fileUploaded="ilustrationImageChange"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

<script>
import moment from "moment";
import vSelect from "vue-select";
import tinymce from "@/components/tinymce.vue";
import ImageUpload from "@/components/ImageUpload.vue";
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  components: {
    Datepicker,
    vSelect,
    tinymce,
    ImageUpload,
  },
  props: {
    pageObj: {
      type: Object, default() {
      }
    },
    action: {type: String, default: null},
    repository: {type: String, default: "pages"},
  },
  data() {
    return {
      page: this.pageObj,
      pageTitle:
        this.repository === "pages"
          ? this.$t("page.name")
          : this.$t("article.name"),
      availablePages: [],
    };
  },
  mixins: [ListUtils, ResourceUtils],
  created() {
    let currentId = null;
    if ("id" in this.page) {
      currentId = this.page.id;
    }
    if (this.repository === "pages") {
      this.getPageList(this.availablePages, currentId);
    }
  },
  setup: () => ({v$: useVuelidate()}),
  validations() {
    return {
      page: {
        name: {required: this.$translateError('required')},
        url: {required: this.$translateError('required')},
      },
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    ilustrationImageChange(data) {
      this.page.file = data["@id"];
      this.page.fileURL = data.url;
    },
    presetSlug() {
      if (this.page.url.length === 0) {
        this.page.url = this.$helper.slugifyURL(this.page.name);
      }
    },
    slugifyURL() {
      if (this.page.url.length !== 0) {
        this.page.url = this.$helper.slugifyURL(this.page.url);
      }
    },
    updateContent(content) {
      this.page.description = content;
    },
    async onSubmit() {
      let repo = this.$WebPages;
      if (this.repository === "articles") {
        repo = this.$Articles;
      }
      const isValid = await this.v$.$validate();

      if (isValid) {
        const userData = JSON.parse(localStorage.getItem("userData"));
        const body = {
          name: this.page.name,
          description: this.page.description,
          user: userData["@id"],
          onOff: this.page.onOff,
          url: this.page.url,
          file: this.page.file,
          excerpt: this.page.excerpt,
          keywords: this.page.keywords,
          metaDescription: this.page.metaDescription,
        };
        let createMessage = "page.created";
        let updateMessage = "page.updated";
        if (this.repository === "pages") {
          body.parent = this.page.parent ? this.page.parent.id : null;
        }
        if (this.repository === "articles") {
          body.date = moment(this.page.date).format()
          body.tags = this.page.tags
          createMessage = "article.created"
          updateMessage = "article.updated"
        }

        if (this.action) {
          this.action === "add"
            ? this.create(
              repo,
              body,
              this.$t(createMessage),
              null,
              this.success,
              this.error
            )
            : this.update(
              repo,
              this.$route.params.id,
              body,
              this.$t(updateMessage),
              null,
              this.successUpdate,
              this.error
            );
        }
      }
    },
    success() {
      this.$router.push(`/${this.repository}`);
    },
    successUpdate() {
      this.$emit("clearAction");
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
